import React from 'react';
import { Main } from "./styles";


function Home() {

  return (
    <Main>
      <div className="input-group">
        <h1>Informe o Código de Rastreamento</h1><br />
        <input type="text" className="form-control"
          onKeyPress={event => {
            if (event.key === 'Enter') {
              window.location.href = (event.target as HTMLInputElement).value;
            }
          }} placeholder="Digite o código de rastreamento" />
      </div>
    </Main>
  );
}


export default Home;