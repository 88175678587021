import styled from "styled-components";

export const Container = styled.section`
  box-shadow: 0px 5px 20px rgb(38 48 60 / 0.05);
  @media (max-width: 700px) {
    box-shadow: 0px 5px 20px rgba(38, 48, 60, 0.15);
  }
`;

export const StatusList = styled.li`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 16px 20px;
  align-items: center;
  @media (max-width: 700px) {
    grid-template-columns: 100%;
  }
  :nth-child(even) {
    background-color: #f2f8fb;
  }
`;

export const Header = styled.header`
  padding: 15px 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 1px solid #f4f4f4;
  h3 {
    margin-top: 20px;
    font-size: 14px;
  }
  @media (max-width: 700px) {
    display: none;
  }
`;

export const StatusInformation = styled.div`
  display: flex;
  align-items: center;
  svg {
    stroke-width: 1;
  }
`;
export const StatusText = styled.div`
  margin-left: 20px;
  h3 {
    font-size: 14px;
  }
  p {
    margin-top: 5px;
    font-size: 12px;
  }
`;
export const StatusTextMovimentation = styled.div`
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5rem;
  }
  @media (max-width: 700px) {
    margin-top: 20px;
  }
`;
export const StatusContainer = styled.ul`
  display: flex;
  flex-direction: column;
`;
