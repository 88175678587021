import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 30px;
  svg {
    stroke-width: 1;
  }
`;

export const Steps = styled.div`
  text-align: center;
  padding: 20px;
  h3 {
    margin-top: 20px;
    font-size: 14px;
  }
  p {
    margin-top: 5px;
    font-size: 12px;
  }
`;
