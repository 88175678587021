import styled from "styled-components";

export const Main = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
  width: 100vw;
  font-family: "Montserrat";
`;

export const Container = styled.div`
  align-self: center;
  width: 960px;
  padding: 20px;

  h1 {
    font-size: 24px;
  }
  span{
    color:red;
  }
  .newTracking{
    text-align:center;
    a{
      font-size:18px;
      font-family: "Montserrat";
    }
    a:hover{
      text-decoration:underline
    }
  }
  @media (max-width: 700px) {
    h1 {
      text-align: center;
    }
  }

`;

export const ContainerLoader = styled.div`
  height: 50vh;
  width: 100vw;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    color: #2859a7;
    margin-top: 5px;
    font-weight: 200;
  }
`;
