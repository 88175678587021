import React, { useEffect, useState } from "react";
import Progress from "../../components/Progress";
import Status from "../../components/Status";
import { Main, Container, ContainerLoader } from "./styles";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";

export interface ITracking {
  data: {
    events: ITrackingEvents[];
    steps: ITrackingSteps[];
  };
}
interface ITrackingEvents {
  date: string;
  description: string;
  destiny: string;
  hour: string;
}
interface ITrackingSteps {
  done: boolean;
  text: string;
}

const Tracking: React.FC = () => {
  const [trackingStatus, setTrackingStatus] = useState<ITracking>({
    data: {
      events: [{ date: "", description: "", destiny: "", hour: "" }],
      steps: [{ done: false, text: "" }],
    },
  });
  let [loadStatusCode, setLoadStatusCode] = useState(400);
  let { tracking } = useParams<any>();

  useEffect(() => {
    api
      .get(`/tracking/${tracking}`)
      .then((response) => {
        setTrackingStatus(response.data);
        setLoadStatusCode(response.data.code);
      })
      .catch((error) => {
        setLoadStatusCode(404)
      });
  }, [tracking]);

  if (loadStatusCode === 400) {
    return (
      <ContainerLoader>
        <ScaleLoader color={"#2859a7"} loading={true} />
        <p>Carregando...</p>
      </ContainerLoader>
    );
  }
  else if (loadStatusCode === 404) {
    return (
      <Main>
        <Container>
          <h1>Rastreio Simples</h1>
          <span>
            <strong>Atenção!</strong>
            &nbsp;O código informado não existe em nossa base de dados, é necessário entrar em contato diretamente com o lojista ou a pessoa que forneceu o código para você.
            <br />
            <br /><strong>Por questões de segurança</strong>, todo atendimento e suporte é feito diretamente pelo usuário responsável que emitiu a etiqueta em nossa plataforma, <strong>através do chat de atendimento.</strong>
          </span>
          <br />
          <br />
          <div className="newTracking">
            <a href="https://rastreio.enviosimples.com.br">Rastrear Novamente</a>
          </div>
        </Container>
      </Main>
    )
  }
  else {
    return (
      <Main>
        <Container>
          <h1>Rastreio Simples</h1>
          {/* <span>
            <strong>Atenção!</strong>
            &nbsp;Caso precise de mais informações sobre o envio desse código, é necessário entrar em contato diretamente com o lojista ou a pessoa que emitiu o envio.
            <br />
            <br /><strong>Por questões de segurança</strong>, todo atendimento e suporte é feito diretamente pelo usuário responsável que emitiu a etiqueta em nossa plataforma, <strong>através do chat de atendimento.</strong>
          </span> */}
          <Progress data={trackingStatus.data} />
          <Status data={trackingStatus.data} />
          <br />
          <br />
          <div className="newTracking">
            <a href="https://rastreio.enviosimples.com.br">Rastrear Novamente</a>
          </div>
        </Container>
      </Main>
    );
  }
};

export default Tracking;
