import React from "react";
import dotenv from "dotenv";
import Routes from "./routes";


dotenv.config();


const App: React.FC = () => {
  return <Routes />;
};

export default App;
