import styled from "styled-components";

export const Main = styled.div`
h1{
  text-align: center;
  align-self: center;
  padding: 2rem;
  font-size: 24px;
  font-family: "Montserrat";
}

.input-group {
  padding: 8rem 0px;
  text-align: center;
}
input.form-control {
  padding: 1em;
  width: 20%;
  font-size: 1.2em;
  border: 1px solid;
  border-radius: 50px;
}
@media (max-width: 700px) {.input-group {
  padding: 4em 0px !important;
}

input.form-control {
  width: 82% !important;
  font-size: 1.1em !important;
  text-align: center;
}}
`;