import React, { Fragment } from "react";
import { FiPackage, FiTruck, FiHome, FiCheckCircle } from "react-icons/fi";
import { Container, Steps } from "./styles";
import { ITracking } from "../../pages/Tracking";

const Progress: React.FC<ITracking> = ({ data }) => {
  try {
    return (
      <Container>
        {data.steps.map((step) => {
          const stepProgress = () => {
            if (step.done === true && step.text === "Coletado/Enviado.") {
              return (
                <Steps>
                  <FiPackage size={75} color="#2859a7" />
                  <h3>{step.text}</h3>
                </Steps>
              );
            }
            if (step.done === false && step.text === "Coletado/Enviado.") {
              return (
                <Steps>
                  <FiPackage size={75} color="#ccc" />
                  <h3>{step.text}</h3>
                </Steps>
              );
            }

            if (step.done === true && step.text === "Encaminhado.") {
              return (
                <Steps>
                  <FiTruck size={75} color="#2859a7" />
                  <h3>{step.text}</h3>
                </Steps>
              );
            }
            if (step.done === true && step.text === "Encaminhado.") {
              return (
                <Steps>
                  <FiTruck size={75} color="#ccc" />
                  <h3>{step.text}</h3>
                </Steps>
              );
            }
            if (step.done === true && step.text === "Saiu para entrega.") {
              return (
                <Steps>
                  <FiTruck size={75} color="#2859a7" />
                  <h3>{step.text}</h3>
                </Steps>
              );
            }
            if (step.done === false && step.text === "Saiu para entrega.") {
              return (
                <Steps>
                  <FiHome size={75} color="#ccc" />
                  <h3>{step.text}</h3>
                </Steps>
              );
            }
            if (step.done === true && step.text === "Entregue.") {
              return (
                <Steps>
                  <FiCheckCircle size={75} color="#25D366" />
                  <h3>{step.text}</h3>
                </Steps>
              );
            }
            if (step.done === false && step.text === "Entregue.") {
              return (
                <Steps>
                  <FiCheckCircle size={75} color="#ccc" />
                  <h3>{step.text}</h3>
                </Steps>
              );
            }
          };
          return <Fragment key={step.text}>{stepProgress()}</Fragment>;
        })}
      </Container>
    );
  } catch {
    return <>Código de rastreio não encontrado</>;
  }
};

export default Progress;
