import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./pages/Home";
import Tracking from "./pages/Tracking";


function Routes() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Home} />
      <Route exact path="/:tracking" component={Tracking} />
    </BrowserRouter>
  );
}

export default Routes;
