import React, { Fragment } from "react";
import {
  Container,
  StatusList,
  Header,
  StatusInformation,
  StatusContainer,
  StatusText,
  StatusTextMovimentation,
} from "./styles";
import { FiPackage, FiTruck, FiHome, FiCheckCircle } from "react-icons/fi";
import { ITracking } from "../../pages/Tracking";

const Status: React.FC<ITracking> = ({ data }) => {
  try {
    return (
      <Container>
        <Header>
          <h3>Status</h3>
          <h3>Andamento</h3>
        </Header>
        <StatusContainer>
          {data.events.map((event) => {
            return (
              <Fragment key={event.hour}>
                <StatusList>
                  <StatusInformation>
                    {event.description === "Objeto postado" ? (
                      <FiPackage size={50} color="#2859a7" />
                    ) : (
                      ""
                    )}
                    {event.description === "Objeto encaminhado" ? (
                      <FiTruck size={50} color="#2859a7" />
                    ) : (
                      ""
                    )}
                    {event.description ===
                    "Objeto saiu para entrega ao destinatário" ? (
                      <FiHome size={50} color="#2859a7" />
                    ) : (
                      ""
                    )}
                    {event.description === "Objeto entregue ao destinatário" ? (
                      <FiCheckCircle size={50} color="#25D366" />
                    ) : (
                      ""
                    )}
                    <StatusText>
                      <h3>{event.description}</h3>
                      <p>
                        {event.date} {event.hour}
                      </p>
                    </StatusText>
                  </StatusInformation>
                  <StatusTextMovimentation>
                    <p>
                      <strong>{event.destiny}</strong>
                    </p>
                  </StatusTextMovimentation>
                </StatusList>
              </Fragment>
            );
          })}
        </StatusContainer>
      </Container>
    );
  } catch {
    return <></>;
  }
};

export default Status;
